import { Flex } from "@appsmith/wds";
import type { ForwardedRef } from "react";
import React, { forwardRef, useLayoutEffect, useState } from "react";
import styles from "./styles.module.css";
import { ChatHeader } from "./ChatHeader";
import { CHAT_SIZE } from "./types";
import { type AIChatProps } from "./types";
import { ChatMessageList } from "./ChatMessageList";
import { ChatInputSection } from "./ChatInputSection";

const _AIChat = (props: AIChatProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    chatDescription,
    chatTitle,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    size,
    thread,
    threadId,
    ...rest
  } = props;
  const [chatHeight, setChatHeight] = useState("");
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";

  useLayoutEffect(() => {
    const canvasElem = document.querySelector(".canvas");

    if (canvasElem) {
      switch (size) {
        case CHAT_SIZE.MEDIUM:
          return setChatHeight("600px");
        case CHAT_SIZE.LARGE:
          return setChatHeight("800px");
        case CHAT_SIZE.FIT_PAGE:
          return setChatHeight(
            getFitPageChatHeight(canvasElem.clientHeight, isEmbed),
          );
        default:
          return setChatHeight(
            getFitPageChatHeight(canvasElem.clientHeight, isEmbed),
          );
      }
    }
  }, [size, isEmbed]);

  const isDeleteThreadDisabled =
    !threadId || isThreadLoading || isWaitingForResponse;

  const getFitPageChatHeight = (canvasHeight: number, isEmbed: boolean) => {
    if (isEmbed) return `calc(${canvasHeight}px`;

    return `calc(${canvasHeight}px - (var(--outer-spacing-4) * 2)`;
  };

  return (
    <Flex
      className={styles.aiChat}
      direction="column"
      ref={ref}
      {...rest}
      style={
        {
          "--chat-height": chatHeight,
        } as React.CSSProperties
      }
    >
      <ChatHeader
        description={chatDescription}
        isDeleteThreadDisabled={isDeleteThreadDisabled}
        onDeleteThread={onDeleteThread}
        title={chatTitle}
      />

      <ChatMessageList
        isThreadLoading={isThreadLoading}
        onApplyAssistantSuggestion={onApplyAssistantSuggestion}
        thread={thread}
      />

      <ChatInputSection
        isThreadLoading={isThreadLoading}
        isWaitingForResponse={isWaitingForResponse}
        onAttachFiles={onAttachFiles}
        onPromptChange={onPromptChange}
        onSubmit={onSubmit}
        prompt={prompt}
        promptInputPlaceholder={promptInputPlaceholder}
      />
    </Flex>
  );
};

export const AIChat = forwardRef(_AIChat);
